<template lang="pug">
div
  div.logo
    img(:src="logo")
  div(v-if="!sessionInfo.crb5c_fowsessionattendanceid" style="margin-left:50px;") Loading...
  div(v-else)(style="margin-top:50px;")
    h1(style="margin-left:50px; font-size:20px;") Client name:
      span(style="margin-left:15px; font-size:20px; font-weight: bold") {{clientInfo.crb5c_client.crb5c_no}}
    h2(style="margin-top:20px;margin-left:50px;font-size:15px") Session name:
      span(style="margin-left:15px; font-size:15px; font-weight: bold") {{clientInfo.crb5c_session.crb5c_sessionname}}
    h2(style="margin-top:20px;margin-left:50px;font-size:15px") Session type:
      span(style="margin-left:15px; font-size:15px; font-weight: bold") {{report}}
  hr.mb-3.mt-5

  div.reportTable
    b-tr.d-flex.mb-4
      b-th(style="border:none;")
        b-btn.reportbtn(@click="addactivitybtn(true)") 
          b-icon(icon="plus-circle" style="color:black;") 
          | &nbsp; Add activity 

    .d-flex.justify-content-center.mt-5.mb-5(v-if="this.tableloading")
      template#table-busy 
        b-spinner(variant="primary" type="border")

    div(v-if="!this.tableloading")
      b-table#table-busy.table(hover :items="items" :fields="fields" thead-class="dsg-purple-th" @row-clicked="viewreport")
        template(v-slot:cell(actions)="{item}") 
          span    
            b-btn.reportbtn(@click="deleteactivitybtn(item)") 
              b-icon(icon="x-circle" style="color:black;")
          span(v-if="item.reportowner == $store.state.msalAccount[0].username")    
            b-btn.reportbtn(@click="viewreport(item)") 
              b-icon(icon="pencil" style="color:black;") 

    div(v-if="!this.items.length")
      div(v-if="gotreportdata")
        h5.indicator.text-center.my-4 No activities
        h6 Click on "Add activity" to begin with the activity report
      .d-flex.justify-content-center(v-else)
        b-spinner(variant="primary" type="border")

  hr.mt-2
  div.reportTable
    b-table-simple.table.borderless
      b-thead
        b-tr.purple
          b-th
          b-th On Arrival
          b-th End of Session  
      b-tbody
        b-tr
          b-th.d-flex.justify-content-center(style="font-weight:normal") Mood 情绪
          b-td.col-4
            v-select(:options="moodOptions" v-model="selectedreport.moodArrival" multiple :reduce="moodOptions => moodOptions.id")
          b-td.col-4
            v-select(:options="moodOptions" v-model="selectedreport.moodEnd" multiple :reduce="moodOptions => moodOptions.id")
        b-tr
          b-th.d-flex.justify-content-center(style="font-weight:normal") Behaviour 行为
          b-td.col-4
            v-select(:options="behaviourOptions" v-model="selectedreport.behaviourArrival" multiple :reduce="behaviourOptions => behaviourOptions.id")
          b-td.col-4
            v-select(:options="behaviourOptions" v-model="selectedreport.behaviourEnd" multiple :reduce="behaviourOptions => behaviourOptions.id")

  //- hr.mt-2
  //- div.reportTable
  //-     label.form-label Click to upload pictures from session:
  //-     b-form-file.form-control(ref="sessionpictures" v-model="session_pictures" class="mt-2" plain multiple @change="sessionpictureselected")
  //-     div(v-for="(picture,pic) in picvalue" :key="`${pic}`" )
  //-       b-img(:src="picture" img-top style="text-align: center;" img-height="180")

  .d-flex.justify-content-center.mt-1
    b-btn.btn-purple.btn-md.mt-5( @click="submitreport") Submit

  b-modal#pickactivity.my-modal(title="Choose activity" centered size='xl' hide-footer hide-header-close header-class="justify-content-center")
    div.mx-4
      .row.mt-3
        .col-sm-12
          .row
            .col-sm-2
              input.radio(type='radio' v-model="activitypickmodal.activity_type" id="phy" name="0" value="physicalActivities")
              label(for="phy") Physical
            .col-sm-2
              input.radio(type='radio' v-model="activitypickmodal.activity_type" id="cog" name="2" value="cognitiveActivities") 
              label(for="cog") Cognitive
            .col-sm-3
              input.radio(type='radio' v-model="activitypickmodal.activity_type" id="psy" name="1" value="psysioActivities")
              label(for="psy") Psycho-socio
            .col-sm-3
              input.radio(type='radio' v-model="activitypickmodal.activity_type" id="nam" name="3" value="namasteActivities")
              label(for="nam") Namaste Care
            //- .col-sm-2(v-if="this.reportType == 2 || this.reportType == 3")
            //-     input.radio(type='radio' v-model="activitypickmodal.activity_type" id="ind" name="4" value="individualActivities")
            //-     label(for="ind") Individual

    .row.mt-4
      .col
        div.mx-4(v-if="this.activitypickmodal.activity_type != 'namasteActivities'")
          input#searchbox.searchbar(type="text" name="searchbox" class="filterinput form-control" placeholder="Search activity..." v-model="activityFilter")
      .col.d-flex.justify-content-end.mx-3
        b-btn.reportbtn.btn-sm(@click="addnewactivity") 
          b-icon.mx-1(icon="plus-circle" style="color:black;") 
          | Add new activity
    div
      label.mx-4(v-if="activityFilter") {{activityFilterResult.length}} result found

    div.d-flex.justify-content-center(v-if="!activityFilter")
      .cardcontainer.mt-4(v-if="this.activitypickmodal.activity_type != 'namasteActivities'")
        label.m-3(v-for="(games,gm) in remainingActivities" :key="`${gm}`" :for="games.crb5c_name" style="font-size:14px" )
          input.radio-card.d-none(type='radio' v-model="selectedactivity.id" :id="games.crb5c_name" :name="games.crb5c_name" :value="games.crb5c_activityprofileid" )
          .card 
            img.card-img(:src="games.crb5c_activityimage ? 'data:image/jpeg;base64,'+games.crb5c_activityimage : noimage" img-top )
            .text-center.mt-2
              | {{games.crb5c_name}}                                

      .cardcontainernamaste.mt-4(v-else)
        label.my-2 Please tick the activities. 请打勾选您尝试过的活动.
        b-table#table-busy.table(hover :items="items_namaste" :fields="fields_namaste" thead-class="dsg-purple-th")
          template(v-slot:cell(checkbox)="{item}") 
            b-form-group
              input(type="checkbox" v-model="namaste_activities_selected" :value="item.activities")

    div(v-else)
      .cardcontainer.mt-4(v-if="this.activitypickmodal.activity_type != 'namasteActivities'")
        label.m-2(v-for="(games,gm) in activityFilterResult" :key="`${gm}`" style="font-size:14px" )
          input.radio-card.d-none(type='radio' v-model="selectedactivity.id" :id="games.crb5c_name" :name="games.crb5c_name" :value="games.crb5c_activityprofileid" )
          .card 
            img.card-img(:src="games.crb5c_activityimage ? 'data:image/jpeg;base64,'+games.crb5c_activityimage : noimage" img-top )
            .text-center.mt-2
              | {{games.crb5c_name}}

    div.d-flex.justify-content-center
      b-btn.savebtn.mt-4(@click="addreportbtn" style="margin-top:10px;margin-bottom:10px") Next

  b-modal#addreport.my-modal(centered size='xl' hide-footer hide-header )
    div.d-flex.justify-content-end
      b-btn.btn-close(@click="$bvModal.hide('addreport')")
    div.mt-4 
      b-card.cardreport.mx-auto(:img-src="selectedactivity.info.crb5c_activityimage ? 'data:image/jpeg;base64,'+selectedactivity.info.crb5c_activityimage : noimage" img-top style="text-align: center;" img-height="200")
        label {{selectedactivity.info.crb5c_name}}
        div
          b-btn.btn-md.mt-2.textbtn.text-secondary(@click="addactivitybtn(false)")
            b-icon(icon="pencil" style="color:black;") 

    div.px-5.my-5
      .row.mt-3  
        .col-sm
          label.form-label(for="exampleFormControlInput1") Behaviour 行为:
          v-select(:options="behaviourOptions" v-model="selectedactivity.val.behaviour" multiple :reduce="behaviourOptions => behaviourOptions.id")

        .col-sm
          label.form-label(for="exampleFormControlInput2") Mood 情绪:
          v-select(:options="moodOptions" v-model="selectedactivity.val.mood" multiple :reduce="moodOptions => moodOptions.id")
      .row.mt-3
        .col-sm
          label.form-label(for="exampleFormControlInput3") Thoughts 思维:
          v-select(:options="thoughtOptions" v-model="selectedactivity.val.thought" multiple :reduce="thoughtOptions => thoughtOptions.id")
        .col-sm
          label.form-label(for="exampleFormControlInput4") Participation 参与:
          v-select(:options="participationOptions" v-model="selectedactivity.val.participation" multiple :reduce="participationOptions => participationOptions.id")
      .row.mt-3
        .col-sm
          label.form-label(for="exampleFormControlInput5") Level of alertness 集中力:
          v-select(:options="levelofAlertOptions" v-model="selectedactivity.val.alertness" multiple :reduce="levelofAlertOptions => levelofAlertOptions.id")
        .col-sm
          label.form-label(for="exampleFormControlInput6") Attention span 注意力持续度:
          v-select(:options="attentionspanOptions" v-model="selectedactivity.val.attention" multiple :reduce="attentionspanOptions => attentionspanOptions.id")
      .row.mt-3 
        .col
          label.form-label(for="exampleFormControlTextarea1") Remarks 评论:
          textarea#exampleFormControlTextarea1.form-control(type="text" v-model="selectedactivity.val.remark")

    div.d-flex.justify-content-center
      b-row
        b-col
          b-btn.savebtn.buttonmodal(@click="savereportbtn" variant="outline-success") Save

  b-modal#addindireport.my-modal(centered size='xl' hide-footer hide-header)
    div.d-flex.justify-content-end
      b-btn.btn-close(@click="$bvModal.hide('addindireport')")
    div.mt-2(v-if="selectedactivity.type != 3")
      b-card.card.mx-auto(:img-src="selectedactivity.info.crb5c_activityimage ? 'data:image/jpeg;base64,'+selectedactivity.info.crb5c_activityimage : noimage" img-top style="text-align: center;" img-height="180")
        label {{selectedactivity.info.crb5c_name}}
        div
          b-btn.btn-md.mt-2.textbtn.text-secondary(@click="addactivitybtn(false)")
            b-icon(icon="pencil" style="color:black;") 
    div.mt-2(v-else)
      b-card.card.mx-auto(:img-src="selectedactivity.info.crb5c_activityimage ? 'data:image/jpeg;base64,'+selectedactivity.info.crb5c_activityimage : noimage" img-top style="text-align: center;" img-height="180")
        label Namaste Care
        div
          b-btn.btn-md.mt-2.textbtn.text-secondary(@click="addactivitybtn(false,'namaste')")
            b-icon(icon="pencil" style="color:black;") 
      label.mx-3.mt-3 Activities done:
      div.badgecontainer.d-flex.justify-content-start.mx-2.mt-1.mb-4
        div.mb-2(v-for="(activities,act) in namaste_activities_selected") 
          b-badge.mx-2 {{activities}}

    div.px-3.mt-4
      label.mb-2 Observations on level of engagement and wellbeing. 参与度和幸福感的水平.
      b-table-simple.table
        b-thead
          b-tr.purple
            b-th Indicator 姿态
            b-th.text-center Beginning of session 开始
            b-th.text-center End of session 结束
        b-tbody
          b-tr
            b-th.reportinput Facial expression 面部表情:
            b-td.col-4
              v-select(:options="facialOptions" v-model="selectedactivity.val.facialBegin" multiple :reduce="facialOptions => facialOptions.id")
            b-td.col-4
              v-select(:options="facialOptions" v-model="selectedactivity.val.facialEnd" multiple :reduce="facialOptions => facialOptions.id")
          b-tr
            b-th.reportinput Eye Contact 眼神交流:
            b-td.col-4
              v-select(:options="eyeContactOptions" v-model="selectedactivity.val.eyeContactBegin" multiple :reduce="eyeContactOptions => eyeContactOptions.id")
            b-td.col-4
              v-select(:options="eyeContactOptions" v-model="selectedactivity.val.eyeContactEnd" multiple :reduce="eyeContactOptions => eyeContactOptions.id")
          b-tr
            b-th.reportinput Interest in communicating 对沟通的兴趣: 
            b-td.col-4
              v-select(:options="interestOptions" v-model="selectedactivity.val.interestBegin" multiple :reduce="interestOptions => interestOptions.id")
            b-td.col-4
              v-select(:options="interestOptions" v-model="selectedactivity.val.interestEnd" multiple :reduce="interestOptions => interestOptions.id")
          b-tr
            b-th.reportinput Body Posture 身体姿态:
            b-td.col-4
              v-select(:options="postureOptions" v-model="selectedactivity.val.postureBegin" multiple :reduce="postureOptions => postureOptions.id")
            b-td.col-4
              v-select(:options="postureOptions" v-model="selectedactivity.val.postureEnd" multiple :reduce="postureOptions => postureOptions.id")
          b-tr
            b-th.reportinput Mood 心情: 
            b-td.col-4
              v-select(:options="moodOptions" v-model="selectedactivity.val.moodBegin" multiple :reduce="moodOptions => moodOptions.id")
            b-td.col-4
              v-select(:options="moodOptions" v-model="selectedactivity.val.moodEnd" multiple :reduce="moodOptions => moodOptions.id")
          b-tr
            b-th.reportinput Behaviour 行为:
            b-td.col-4
              v-select(:options="behaviourOptions" v-model="selectedactivity.val.behaviourBegin" multiple :reduce="behaviourOptions => behaviourOptions.id")
            b-td.col-4
              v-select(:options="behaviourOptions" v-model="selectedactivity.val.behaviourEnd" multiple :reduce="behaviourOptions => behaviourOptions.id")
    .row(style="margin-left:10px; padding-right:25px")
      .col
        label.form-label(for="exampleFormControlTextarea1") Remarks 评论:
        textarea#exampleFormControlTextarea1.form-control(type="text" v-model="selectedactivity.val.remark")

    div.mt-4.d-flex.justify-content-center
      b-row
        b-col
          b-btn.buttonmodal.savebtn(@click="savereportbtn") Save

  b-modal#wrongUsername.my-modal(title="Invalid Owner" centered size='xl' hide-footer hide-header)
    div(v-if="this.authenticatedusername").d-flex.justify-content-center
      h4.mt-4.mb-4 Unable to make changes, the report can be only edited by: "{{this.authenticatedusername}}"

  b-modal#reportDone.my-modal(title="Report Completed" centered size='lg' hide-footer hide-header)
    div.d-flex.justify-content-center
      h4.mt-4.mb-4 Report for this client is completed, you can close this window.

</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Fuse from "fuse.js";
import logo from "../assets/dsg_logo.png";
import noimage from "../assets/no-image.jpg"

export default {
  name: "SessionView",
  components: {
    vSelect,
  },
  computed: {
    report() {
      switch (this?.clientInfo?.crb5c_session?.crb5c_sessionreporttype) {
        case 0:
          return 'Mild';
        case 1:
          return 'Moderate';
        case 2:
          return 'Namaste';
        case 3:
          return 'Individual';
        default:
          return '';
      }
    },
    activityFilterResult() {
      const options = {
        // isCaseSensitive: false,
        // includeScore: false,
        // shouldSort: true,
        // includeMatches: false,
        // findAllMatches: false,
        // minMatchCharLength: 1,
        // location: 0,
        // threshold: 0.6,
        // distance: 100,
        // useExtendedSearch: false,
        // ignoreLocation: false,
        // ignoreFieldNorm: false,
        // fieldNormWeight: 1,
        keys: ["crb5c_name"],
      };

      const fuse = new Fuse(this.remainingActivities, options);

      // Change the pattern
      const pattern = this.activityFilter;
      return fuse.search(pattern).map(i => ({ ...i.item })) || [];
    },
    selectedActivityNow() {
      return (
        this.activitypickmodal?.[this.activitypickmodal?.activity_type] || []
      );
    },
    inlist() {
      return this.items;
    },
    remainingActivities() {
      return (
        this.activitypickmodal?.[this.activitypickmodal?.activity_type]?.filter(
          (a) =>
            !this.items.find((i) => i.activityid == a.crb5c_activityprofileid)
        ) || this.activitypickmodal?.[this.activitypickmodal?.activity_type]
      ) || (
          this.activitypickmodal?.activityobj?.filter(
            (a) =>
              !this.items.find((i) => i.activityid == a.crb5c_activityprofileid)
          ) || this.activitypickmodal?.[this.activitypickmodal?.activity_type]
        );
    },
    isHeaderFilled() {
      if (
        this.selectedreport.moodArrival.length &&
        this.selectedreport.moodEnd.length &&
        this.selectedreport.behaviourArrival.length &&
        this.selectedreport.behaviourEnd.length
      ) {
        return true;
      }
      return false;
    },
    isFilled() {
      if (this.items.length) {
        return true;
      }
      return false;
    },
    isActivityCompleted() {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].status == "Not Complete") {
          return false;
        }
      }
      return true;
    },
    mildRequired() {
      if (this.reportType == 0 && this.items.length > 2) {
        return true;
      }
      return false;
    },
  },
  created() {
    this.reInitInterval = setInterval(() => this.$root.init(), 60000 * 4);
  },
  async mounted() {
    const sId = this.$route.query.id;
    const clientId = this.$route.query.client_id;
    const localSid = localStorage.getItem("sessionId");
    const localClientid = localStorage.getItem("clientId");

    if (sId) {
      localStorage.setItem("sessionId", sId);
      this.$store.commit("sessionId", sId);
      localStorage.setItem("clientId", clientId);
      this.$store.commit("clientId", clientId);
    }
    await this.$root.init();
    if (localSid === clientId) {
      this.$store.commit("sessionId", localSid);
      this.$store.commit("clientId", localClientid);
    }

    await this.fetchSession();
    await this.getGlobalDefinitions();

    this.setAddActivityData();
  },
  watch: {
    selectedactivity: {
      handler(val) {
        if (this.submitmode !== "edit")
          localStorage.setItem("selectedactivity", JSON.stringify(val));
      },
      deep: true,
    },
    selectedreport: {
      handler(val) {

        localStorage.setItem("selectedreport", JSON.stringify(val));
      },
      deep: true,
    },
    activitypickmodal: {
      handler(val) {
        console.log('ok changed but2', this.submitmode)
        if (this.submitmode !== "edit")
          localStorage.setItem("activityModal", JSON.stringify(val));
      },
      deep: true,
    },
    items: {
      handler(newVal, oldVal) {
        const latest = newVal[newVal.length - 1];
        if (
          newVal.length > oldVal.length &&
          this.gotreportdata &&
          latest?.reportowner?.toLowerCase() !==
          this.owner_username?.toLowerCase()
        ) {
          alert(
            `${latest.reportby} has just submitted their report on ${latest.name} activity!`
          );

          if (latest.id === this.selectedactivity.id) {
            this.activitypickmodal.show = false;
            this.selectedactivity = {
              id: "",
              info: [],
              type: "",
              sessactid: "",
              val: {
                behaviour: [],
                mood: [],
                thought: [],
                participation: [],
                alertness: [],
                attention: [],
                facialBegin: [],
                eyeContactBegin: [],
                interestBegin: [],
                postureBegin: [],
                behaviourBegin: [],
                moodBegin: [],
                facialEnd: [],
                eyeContactEnd: [],
                interestEnd: [],
                postureEnd: [],
                behaviourEnd: [],
                moodEnd: [],
                remark: "",
              },
            };
            this.activitypickmodal = {
              show: false,
              activity_type: "",
              activity_name: "",
              activityobj: [],
              physicalActivities: [],
              psysioActivities: [],
              cognitiveActivities: [],
              namasteActivities: [],
              individualActivities: [],
            };
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    populatePhysitype() {
      const actName = this.activitypickmodal.activity_name;
      const currentAct = this.activitypickmodal.activityobj.find(a => a.crb5c_name == actName);
      this.activitypickmodal.activity_type = currentAct.crb5c_activitytype;
    },
    notInListYet(id) {
      return !this.items.find(i => i.activityid === id);
    },
    clearAutoSave() {
      localStorage.removeItem("activityModal");
      localStorage.removeItem("selectedactivity");
      localStorage.removeItem("selectedreport");
    },
    clearSessionCache() {
      localStorage.removeItem("sessionId");
      localStorage.removeItem("clientId");
    },
    setAddActivityData() {
      const selectedactivity = localStorage.getItem("selectedactivity");
      const activitymodal = localStorage.getItem("activityModal");
      const selectedreport = localStorage.getItem("selectedreport");

      try {
        if (selectedactivity && activitymodal) {
          let data = JSON.parse(selectedactivity);
          let activitymodaldata = JSON.parse(activitymodal);

          this.$set(this, "selectedactivity", data);
          this.$set(this, "activitypickmodal", activitymodaldata);
        }

        if (selectedreport) {
          let selectedreportdata = JSON.parse(selectedreport);
          this.$set(this, "selectedreport", selectedreportdata);
        }

        this.submitmode = 'add';
      } catch (e) {
        console.log("Invalid autosave for selected activity. Ignoring.");
      }
    },
    async getGlobalDefinitions() {
      const { data: globalx } = await this.$store.state.axios.get(
        `GlobalOptionSetDefinitions(9e739642-91c1-ec11-983e-000d3ac88352)`
      );
      const rooms = {};

      for (let opt of globalx.Options) {
        rooms[opt.Value] = opt.Label.UserLocalizedLabel.Label;
      }
      this.rooms = rooms;
    },

    async getClientData() {
      const room_id = this.sessionInfo.crb5c_room;
      let ifNull = "";
      if (this.rooms[room_id] === "No Room") {
        ifNull = "or crb5c_room eq null";
      }
      let paramObj = {
        $expand: `crb5c_client,crb5c_session`,
        $filter: `(crb5c_room eq ${room_id}${ifNull}) and crb5c_session/crb5c_fow_sessionid eq '${this.sessionInfo._crb5c_session_value}' and crb5c_client/crb5c_fow_customerid eq '${this.$store.state.clientId}'`,
      };
      let params = new URLSearchParams(paramObj);

      let { data: clientData } = await this.$store.state.axios.get(
        `crb5c_fowsessionattendances/?${params.toString()}`
      );

      this.clientInfo = clientData.value[0];
      this.reportType = this.clientInfo.crb5c_session.crb5c_sessionreporttype;

      if (this.clientInfo.crb5c_reportstatus == "Done") {
        this.$bvModal.show("reportDone");
        return;
      }
      await this.getreportdata();
      this.gotreportdata = true;
      this.reportFetchingInterval = setInterval(async () => {
        await this.getreportdata();
        this.tableloading = false;
      }, 5000);
    },
    async getreportdata() {
      let paramObj = {
        $filter: `crb5c_SessionAttendance/crb5c_fowsessionattendanceid eq '${this.sessionInfo.crb5c_fowsessionattendanceid}'`,
      };
      let params = new URLSearchParams(paramObj);
      let { data: clientData } = await this.$store.state.axios.get(
        `crb5c_fowsessionactivities/?${params.toString()}`
      );
      const items_data = [];

      for (let i = 0; i < clientData.value.length; i++) {
        let num = 1;
        items_data.push({
          no: num + i,
          id: clientData.value[i].crb5c_fowsessionactivityid,
          activityid: clientData.value[i].crb5c_activityid,
          name: clientData.value[i].crb5c_name,
          reportby: clientData.value[i].crb5c_reportdoneby,
          reportowner: clientData.value[i].crb5c_reportownerid,
          status: clientData.value[i].crb5c_reportstatus,
        });
      }
      // console.log('items:', items_data)
      this.items = items_data;
    },
    async fetchSession() {
      let paramObj = {
        $filter: `crb5c_fowsessionattendanceid eq '${this.$store.state.sessionId}'`,
      };
      let params = new URLSearchParams(paramObj);
      let { data: clientData } = await this.$store.state.axios.get(
        `/crb5c_fowsessionattendances?${params.toString()}`
      );

      this.sessionInfo = clientData.value[0];
      //console.log('session info: ',this.sessionInfo)
      this.getClientData();
    },

    async addactivitybtn(addingNotChanging) {
      console.log(this.reportType)
      console.log(this.session_pictures)
      const selectedactivity = localStorage.getItem('selectedactivity');
      if (selectedactivity && this.notInListYet(selectedactivity.id) && addingNotChanging) {
        try {
          JSON.parse(selectedactivity);
          this.setAddActivityData();
          await this.addreportbtn();
          return;
        } catch (e) {
          this.owner_username = this.$store.state.msalAccount[0].username;
          this.owner_name = this.$store.state.msalAccount[0].name;
          this.activitypickmodal.show = true;
          this.$bvModal.show("pickactivity");
          await this.refreshActivities();
          this.submitmode = "add";
        }
      }
      this.owner_username = this.$store.state.msalAccount[0].username;
      this.owner_name = this.$store.state.msalAccount[0].name;
      this.activitypickmodal.show = true;
      this.$bvModal.hide("addreport");
      this.$bvModal.hide("addindireport");
      this.$bvModal.show("pickactivity");
      await this.refreshActivities();
    },

    async refreshActivities() {
      let paramObj = {
        $select:
          "crb5c_activityprofileid, crb5c_name, crb5c_activityimage, crb5c_activitytype",
      };
      let params = new URLSearchParams(paramObj);
      let { data: activityData } = await this.$store.state.axios.get(
        `crb5c_activityprofiles/?${params.toString()}`
      );
      this.activitypickmodal.activityobj = activityData.value;
      this.populateActivitylist();
    },
    async addreportbtn() {
      this.runtypeoptioncheck();
      if (this.submitmode == "" || this.submitmode == "add") {
        this.submitmode = "add";
      } else {
        this.submitmode = "edit";
      }
      if (this.activitypickmodal.activity_type == "namasteActivities" || this.selectedactivity.id == 'Namaste') {
        this.activitypickmodal.activity_name = 'Namaste Care'
      }
      else {
        let paramObj = {
          $select: "crb5c_name, crb5c_activityimage, crb5c_activitytype",
          $filter: `crb5c_activityprofileid eq '${this.selectedactivity.id}'`
        };
        let params = new URLSearchParams(paramObj);
        let { data: activityinfo } = await this.$store.state.axios.get(
          `crb5c_activityprofiles/?${params.toString()}`
        );
        this.selectedactivity.info = activityinfo.value[0]
        this.activitypickmodal.activity_name = this.selectedactivity.info.crb5c_name;
      }
      this.$bvModal.hide("pickactivity");
      console.log(this.activitypickmodal.activity_type)
      console.log(this.selectedactivity.type)
      if (this.activitypickmodal.activity_type == "namasteActivities" || this.selectedactivity.type == 3) {
        this.$bvModal.show("addindireport");
      } else {
        this.populatePhysitype();
        this.$bvModal.show("addreport");
      }
    },
    resetmodal() {
      this.namaste_activities_selected = [];
      this.submitmode = "";
      (this.activitypickmodal = {
        show: false,
        activity_type: "",
        activity_name: "",
      }),
        (this.selectedactivity = {
          id: "",
          info: [],
          type: "",
          sessactid: "",
          val: {
            behaviour: [],
            mood: [],
            thought: [],
            participation: [],
            alertness: [],
            attention: [],
            interest: [],
            facial: [],
            posture: [],
            facialBegin: [],
            eyeContactBegin: [],
            interestBegin: [],
            postureBegin: [],
            behaviourBegin: [],
            moodBegin: [],
            facialEnd: [],
            eyeContactEnd: [],
            interestEnd: [],
            postureEnd: [],
            behaviourEnd: [],
            moodEnd: [],
            remark: "",
          },
        });
    },
    closereportbtn() {
      this.$bvModal.hide("pickactivity");
      this.$bvModal.hide("addreport");
      this.$bvModal.hide("addindireport");
      this.resetmodal();
    },
    async viewreport(info) {
      console.log(info)
      this.resetmodal();
      await this.refreshActivities();
      this.submitmode = "edit";
      console.log("report:", this.reportType);
      this.selectedactivity.sessactid = info.id;
      console.log(info);
      if (info.reportowner != this.$store.state.msalAccount[0].username) {
        this.authenticatedusername = info.reportowner;
        this.$bvModal.show("wrongUsername");
        return;
      }

      let paramObj = {
        $filter: `crb5c_fowsessionactivityid eq '${info.id}'`,
      };
      let params = new URLSearchParams(paramObj);
      let { data: clientData } = await this.$store.state.axios.get(
        `crb5c_fowsessionactivities/?${params.toString()}`
      );
      this.selectedactivity.type = clientData.value[0].crb5c_physitype;
      this.owner_name = clientData.value[0].crb5c_reportdoneby;
      this.owner_username = clientData.value[0].crb5c_reportownerid;
      console.log('type:', this.selectedactivity.type)
      console.log(clientData.value[0])

      if (this.selectedactivity.type != 3) {
        if (clientData.value[0].crb5c_behaviour) {
          this.selectedactivity.val.behaviour =
            clientData.value[0].crb5c_behaviour.split(",").map(Number);
        }
        if (clientData.value[0].crb5c_mood) {
          this.selectedactivity.val.mood = clientData.value[0].crb5c_mood
            .split(",")
            .map(Number);
        }
        if (clientData.value[0].crb5c_thoughts) {
          this.selectedactivity.val.thought = clientData.value[0].crb5c_thoughts
            .split(",")
            .map(Number);
        }
        if (clientData.value[0].crb5c_participation) {
          this.selectedactivity.val.participation =
            clientData.value[0].crb5c_participation.split(",").map(Number);
        }
        if (clientData.value[0].crb5c_levelofalertness) {
          this.selectedactivity.val.alertness =
            clientData.value[0].crb5c_levelofalertness.split(",").map(Number);
        }
        if (clientData.value[0].crb5c_attentionspan) {
          this.selectedactivity.val.attention =
            clientData.value[0].crb5c_attentionspan.split(",").map(Number);
        }
      } else {

        console.log(clientData.value[0].crb5c_namasteactivities);
        if (clientData.value[0].crb5c_namasteactivities) {
          this.namaste_activities_selected =
            clientData.value[0].crb5c_namasteactivities
              .split(",")
              .map(String);
        }
        if (clientData.value[0].crb5c_namastebeginbehaviour) {
          this.selectedactivity.val.behaviourBegin =
            clientData.value[0].crb5c_namastebeginbehaviour
              .split(",")
              .map(Number);
        }
        if (clientData.value[0].crb5c_namasteendbehaviour) {
          this.selectedactivity.val.behaviourEnd =
            clientData.value[0].crb5c_namasteendbehaviour
              .split(",")
              .map(Number);
        }
        if (clientData.value[0].crb5c_namastebeginmood) {
          this.selectedactivity.val.moodBegin =
            clientData.value[0].crb5c_namastebeginmood.split(",").map(Number);
        }
        if (clientData.value[0].crb5c_namasteendmood) {
          this.selectedactivity.val.moodEnd =
            clientData.value[0].crb5c_namasteendmood.split(",").map(Number);
        }
        if (clientData.value[0].crb5c_namastebeginfacialexpression) {
          this.selectedactivity.val.facialBegin =
            clientData.value[0].crb5c_namastebeginfacialexpression
              .split(",")
              .map(Number);
        }
        if (clientData.value[0].crb5c_namasteendfacialexpression) {
          this.selectedactivity.val.facialEnd =
            clientData.value[0].crb5c_namasteendfacialexpression
              .split(",")
              .map(Number);
        }
        if (clientData.value[0].crb5c_namastebegininterestincommunicating) {
          this.selectedactivity.val.interestBegin =
            clientData.value[0].crb5c_namastebegininterestincommunicating
              .split(",")
              .map(Number);
        }
        if (clientData.value[0].crb5c_namasteendinterestincommunicating) {
          this.selectedactivity.val.interestEnd =
            clientData.value[0].crb5c_namasteendinterestincommunicating
              .split(",")
              .map(Number);
        }
        if (clientData.value[0].crb5c_namastebeginbodyposture) {
          this.selectedactivity.val.postureBegin =
            clientData.value[0].crb5c_namastebeginbodyposture
              .split(",")
              .map(Number);
        }
        if (clientData.value[0].crb5c_namasteendbodyposture) {
          this.selectedactivity.val.postureEnd =
            clientData.value[0].crb5c_namasteendbodyposture
              .split(",")
              .map(Number);
        }
        if (clientData.value[0].crb5c_namastebegineyecontact) {
          this.selectedactivity.val.eyeContactBegin =
            clientData.value[0].crb5c_namastebegineyecontact
              .split(",")
              .map(Number);
        }
        if (clientData.value[0].crb5c_namasteendeyecontact) {
          this.selectedactivity.val.eyeContactEnd =
            clientData.value[0].crb5c_namasteendeyecontact
              .split(",")
              .map(Number);
        }
      }
      this.selectedactivity.val.remark = clientData.value[0].crb5c_addremarks;
      this.selectedactivity.id = clientData.value[0].crb5c_activityid;
      this.addreportbtn();
    },
    runtypeoptioncheck() {
      switch (this.activitypickmodal.activity_type) {
        case "physicalActivities":
        case "0":
          this.selectedactivity.type = 0;
          break;
        case "psysioActivities":
        case "1":
          this.selectedactivity.type = 1;
          break;
        case "cognitiveActivities":
        case "2":
          this.selectedactivity.type = 2;
          break;
        case "namasteActivities":
        case "3":
          this.selectedactivity.type = 3;
          this.activitypickmodal.activity_name = "Namaste Care";
          break;
        case "individualActivities":
        case "4":
          this.selectedactivity.type = 4;
          break;
      }
    },
    async savereportbtn() {
      this.tableloading = true;
      this.runtypeoptioncheck();
      let payload = {};
      let activityArray = [];
      let keyvalues = {};
      let isFilled = true;
      let activityid = '';
      let addActivity = (obj, type) => {

        if (this.selectedactivity.type == 3) {
          activityid = 'Namaste';
        }
        else {
          activityid = this.selectedactivity.id;
        }

        let activityObj = {
          crb5c_physitype: type,
          crb5c_name: this.activitypickmodal.activity_name,
          crb5c_reportdoneby: this.owner_name || this.$store.state.msalAccount[0].name,
          crb5c_reportownerid: this.owner_username || this.$store.state.msalAccount[0].username,
          crb5c_activityid: activityid,
          crb5c_addremarks: obj.remark,
          "crb5c_Client@odata.bind": `crb5c_fow_customers(${this.sessionInfo._crb5c_client_value})`,
          "crb5c_SessionAttendance@odata.bind": `crb5c_fowsessionattendances(${this.sessionInfo.crb5c_fowsessionattendanceid})`,
        };

        if (this.selectedactivity.type != 3) {
          console.log("running normal");
          keyvalues = {
            behaviour: "crb5c_behaviour",
            mood: "crb5c_mood",
            thought: "crb5c_thoughts",
            participation: "crb5c_participation",
            alertness: "crb5c_levelofalertness",
            attention: "crb5c_attentionspan",
          };
        } else {
          console.log("running namaste");
          keyvalues = {
            facialBegin: "crb5c_namastebeginfacialexpression",
            eyeContactBegin: "crb5c_namastebegineyecontact",
            interestBegin: "crb5c_namastebegininterestincommunicating",
            postureBegin: "crb5c_namastebeginbodyposture",
            behaviourBegin: "crb5c_namastebeginbehaviour",
            moodBegin: "crb5c_namastebeginmood",
            facialEnd: "crb5c_namasteendfacialexpression",
            eyeContactEnd: "crb5c_namasteendeyecontact",
            interestEnd: "crb5c_namasteendinterestincommunicating",
            postureEnd: "crb5c_namasteendbodyposture",
            behaviourEnd: "crb5c_namasteendbehaviour",
            moodEnd: "crb5c_namasteendmood",
          };
          activityObj['crb5c_namasteactivities'] = this.namaste_activities_selected.join(",");
        }

        for (let key in keyvalues) {
          let val = obj[key].join(",");
          let inskey = keyvalues[key];
          if (!val) {
            isFilled = false;
            continue;
          }
          console.log(inskey)
          activityObj[inskey] = val;
        }

        if (!isFilled) {
          activityObj.crb5c_reportstatus = "Not Complete";
        } else {
          activityObj.crb5c_reportstatus = "Complete";
        }

        activityArray.push(activityObj);
        console.log(activityObj)
      };

      addActivity(this.selectedactivity.val, this.selectedactivity.type);

      if (activityArray.length) {
        payload = activityArray;
      }

      if (this.submitmode == "add") {
        console.log("payload", payload);
        const { data } = await this.$store.state.axios.post(
          "/crb5c_fowsessionactivities",
          payload
        );
        console.log(data);
      } else if (this.submitmode == "edit") {
        console.log("payload", payload);
        const { datapatch } = await this.$store.state.axios.patch(
          `/crb5c_fowsessionactivities(${this.selectedactivity.sessactid})`,
          payload
        );
        console.log(datapatch);
      }
      this.$bvModal.hide("addreport");
      this.$bvModal.hide("addindireport");
      this.$bvModal.hide("pickactivity");
      this.closereportbtn();
      this.getreportdata();

      this.clearAutoSave();

      setTimeout(() => (this.tableloading = false), 1000);
      await this.refreshActivities();
      this.resetmodal();
    },
    async deleteactivitybtn(item) {
      this.tableloading = true;
      console.log(item);
      const proceed = confirm(
        'You are about to delete "' + item.name + '" activity from the report'
      );
      if (!proceed) {
        return;
      }
      const { datapatch } = await this.$store.state.axios.delete(
        `/crb5c_fowsessionactivities(${item.id})`
      );
      console.log(datapatch);
      this.getreportdata();
      setTimeout(() => (this.tableloading = false), 1000);
    },
    async submitreport() {
      if (!this.isHeaderFilled) {
        alert(
          "Please fill up the MOOD and BEHAVIOUR for arrival and end of session!"
        );
        return;
      }
      if (!this.isFilled) {
        alert("Please Add atleast one activity before submission");
        return;
      }
      if (this.reportType == 0) {
        if (!this.mildRequired) {
          alert("Mild report requires minimum 3 activites");
          return;
        }
      }
      if (!this.isActivityCompleted) {
        alert("Please COMPLETE all the activity details before submitting");
        return;
      }
      console.log(this.isActivityCompleted);
      let activityObj = {
        "crb5c_client@odata.bind": `crb5c_fow_customers(${this.sessionInfo._crb5c_client_value})`,
        "crb5c_session@odata.bind": `crb5c_fowsessionattendances(${this.sessionInfo.crb5c_fowsessionattendanceid})`,
      };
      let payload = {};
      let reportArray = [];
      //function to format data and insert only if present
      let appendData = (key, value) => {
        if (Array.isArray(value)) {
          value = value.join(",");
        }
        if (value) {
          activityObj[key] = value;
        }
      };
      appendData("crb5c_mood", this.selectedreport.moodArrival);
      appendData("crb5c_mood_end", this.selectedreport.moodEnd);
      appendData("crb5c_behaviour", this.selectedreport.behaviourArrival);
      appendData("crb5c_behaviour_end", this.selectedreport.behaviourEnd);

      activityObj['crb5c_reporttype'] = this.reportType;
      reportArray.push(activityObj);
      payload = reportArray;
      console.log(payload)

      console.log("payload", payload);
      const { data } = await this.$store.state.axios.post(
        "/crb5c_fow_session_reports",
        payload
      );
      console.log(data);

      const payload2 = { crb5c_reportcompletion: "Done" };
      const { data2 } = this.$store.state.axios.patch(
        `/crb5c_fowsessionattendances(${this.$store.state.sessionId})`,
        payload2
      );
      console.log(data2);
      alert("Success! You may see your report in the Attendance Dashboard.");
      this.clearSessionCache();
      this.clearAutoSave();
      window.close();
    },
    populateActivitylist() {

      let item_namas = [];

      this.activitypickmodal.physicalActivities =
        this.activitypickmodal.activityobj.filter((item) => {
          return item.crb5c_activitytype.includes("0");
        });
      this.activitypickmodal.psysioActivities =
        this.activitypickmodal.activityobj.filter((item) => {
          return item.crb5c_activitytype.includes("1");
        });
      this.activitypickmodal.cognitiveActivities =
        this.activitypickmodal.activityobj.filter((item) => {
          return item.crb5c_activitytype.includes("2");
        });
      this.activitypickmodal.namasteActivities =
        this.activitypickmodal.activityobj.filter((item) => {
          return item.crb5c_activitytype.includes("3");
        });

      this.activitypickmodal.individualActivities =
        this.activitypickmodal.activityobj.filter((item) => {
          return !item.crb5c_activitytype.includes("3");
        });


      console.log(this.activitypickmodal.individualActivities)
      console.log('cognitive', this.activitypickmodal.cognitiveActivities)

      for (let i = 0; i < this.activitypickmodal.namasteActivities.length; i++) {
        let num = 1;
        item_namas.push({
          no: num + i,
          activities: this.activitypickmodal.namasteActivities[i].crb5c_name,
        })
      }
      this.items_namaste = item_namas;
      console.log(this.activitypickmodal.activityobj)
    },
    sessionpictureselected() {
      const sessionpictures = this.$refs["sessionpictures"];
      this.$nextTick(() => {
        for (let x = 0; x < sessionpictures.files.length; x++) {
          console.log("running")
          this.getBase64(sessionpictures.files[x]);
        }
      });
    },
    getBase64(file) {
      this.storepics64
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        //console.log(reader.result);
        console.log("done");
        this.picvalue.push(reader.result);
        this.storepics64.push(file)
      };
    },
    addnewactivity() {
      this.$bvModal.hide("pickactivity");
      window.open(
        `https://dsg-fow-dev.crm5.dynamics.com/main.aspx?appid=45923c4d-8cc1-ec11-983e-000d3ac885f2&pagetype=entitylist&etn=crb5c_activityprofile&viewid=cf10ab3d-48b5-489d-82f4-8525fd9465e0&viewType=1039`
      );
    },
  },

  data() {
    return {
      storepics64: [],
      picvalue: [],
      session_pictures: [],
      logo,
      noimage,
      namaste_activities_selected: [],
      activityFilter: '',
      tableloading: false,
      activitypickmodal: {
        show: false,
        activity_type: "",
        activity_name: "",
        activityobj: [],
        physicalActivities: [],
        psysioActivities: [],
        cognitiveActivities: [],
        namasteActivities: [],
        individualActivities: [],
      },
      authenticatedusername: "",
      reportType: "",
      submitmode: "",
      owner_name: "",
      owner_username: "",
      selectedreport: {
        moodArrival: [],
        behaviourArrival: [],
        moodEnd: [],
        behaviourEnd: [],
      },
      selectedactivity: {
        id: "",
        info: [],
        type: "",
        sessactid: "",
        val: {
          behaviour: [],
          mood: [],
          thought: [],
          participation: [],
          alertness: [],
          attention: [],
          facialBegin: [],
          eyeContactBegin: [],
          interestBegin: [],
          postureBegin: [],
          behaviourBegin: [],
          moodBegin: [],
          facialEnd: [],
          eyeContactEnd: [],
          interestEnd: [],
          postureEnd: [],
          behaviourEnd: [],
          moodEnd: [],
          remark: "",
        },
      },
      items_namaste: [],
      fields_namaste: [{
        key: "activities",
        label: "Activities Tried 尝试的活动"
      },
      {
        key: "checkbox",
        label: "Tick (*)"
      },
      ],
      items: [],
      fields: [
        {
          key: "no",
          label: "#",
        },
        {
          key: "name",
          label: "Activity Name",
        },
        {
          key: "reportby",
          label: "Report Completed by",
        },
        {
          key: "status",
          label: "Status",
          tdClass: (value) =>
            value == "Complete" ? "text-success" : "text-danger",
        },
        {
          key: "actions",
          label: "Action",
        },
      ],
      sessionInfo: {},
      rooms: {},
      clientInfo: {},
      behaviourOptions: [
        { label: "Cooperative 合作", img: "../assets/expressionIcon/happy.png", id: 0, },
        { label: "Uncooperative 不合作", img: "../assets/expressionIcon/happy.png", id: 2, },
        { label: "Restless 不安", img: "../assets/expressionIcon/happy.png", id: 3 },
        { label: "Agitated 激动 ", img: "../assets/expressionIcon/happy.png", id: 4 },
        { label: "Aggressive 暴力", img: "../assets/expressionIcon/happy.png", id: 5, },
      ],
      moodOptions: [
        { label: "Elated 兴高采烈", img: "./assets/expressionIcon/Elated.png", id: 0 },
        { label: "Happy 开心", img: "./assets/expressionIcon/happy.png", id: 1 },
        { label: "Neutral 平静", img: "./assets/expressionIcon/neutral.png", id: 2 },
        { label: "Irritable 急躁", img: "./assets/expressionIcon/dissapointed.png", id: 3, },
        { label: "Angry 生气 ", img: "./assets/expressionIcon/angry.png", id: 4 },
        { label: "Anxious 紧张 ", img: "./assets/expressionIcon/shocked.png", id: 5 },
        { label: "Sad 伤心", img: "./assets/expressionIcon/sad.png", id: 6 },
        { label: "Depressed 忧郁 ", img: "./assets/expressionIcon/frustrated.png", id: 7, },
      ],
      thoughtOptions: [
        { label: "Relevant 相关", img: "/assets/expressionIcon/smiling.png", id: 0 },
        { label: "Irrelevant 不切题的", img: "/assets/expressionIcon/raised_eyebrow.png", id: 1, },
        { label: "Inconsistent 不一致的", img: "/assets/expressionIcon/questioning.png", id: 2, },
        { label: "Preoccupied 想心事", img: "/assets/expressionIcon/squinting.png", id: 3, },
        { label: "Delusional 错觉", img: "/assets/expressionIcon/drunk.png", id: 4 },
      ],
      participationOptions: [
        { label: "Yes 有", img: "/assets/expressionIcon/smiling.png", id: 0 },
        { label: "Sometimes 有时候", img: "/assets/expressionIcon/neutral.png", id: 1, },
        { label: "Not At All 没有", img: "/assets/expressionIcon/worried.png", id: 2 },
      ],
      levelofAlertOptions: [
        { label: "Alert 很有精神", img: "/assets/expressionIcon/happy.png", id: 0 },
        { label: "Lethargic 很累", img: "/assets/expressionIcon/tired.png", id: 1 },
        { label: "Fluctuating 时好时坏", img: "/assets/expressionIcon/fed_up.png", id: 2, },
      ],
      attentionspanOptions: [
        { label: "Good 好 (> 30 min 分钟)", img: "/assets/expressionIcon/happy.png", id: 0, },
        { label: "Fair 一般 (15-30 min 分钟)", img: "/assets/expressionIcon/smiling.png", id: 1, },
        { label: "Poor 不好 (< 15 min 分钟)", img: "/assets/expressionIcon/neutral.png", id: 2, },
      ],
      facialOptions: [
        { label: "Passive 无表情", id: 0 },
        { label: "Grimacing 调皮", id: 1 },
        { label: "Frowning 皱眉头", id: 2 },
        { label: "Frightened 害怕", id: 3 },
        { label: "Smilling 微笑", id: 4 },
      ],
      eyeContactOptions: [
        { label: "Closed eye 闭眼", id: 0 },
        { label: "Looking away 斜视", id: 1 },
        { label: "Passive", id: 2 },
        { label: "Blank look 没反应", id: 3 },
        { label: "Little eye contact 眼神少交流", id: 4 },
        { label: "Good eye contact 有眼神交流", id: 5 },
      ],
      interestOptions: [
        { label: "Avoiding 避免", id: 0 },
        { label: "Listening 注意听 ", id: 1 },
        { label: "Non-verbal response 非语言沟通", id: 2 },
        { label: "Unclear verbal response 言语不清", id: 3 },
        { label: "Clear verbal response 沟通清晰", id: 4 },
      ],
      postureOptions: [
        { label: "Restless 不安", id: 0 },
        { label: "Tense 紧张", id: 1 },
        { label: "Slouched 没精打采 ", id: 2 },
        { label: "Relaxed 自在", id: 3 },
        { label: "Jerky 不稳重", id: 4 },
      ],
      reInitInterval: null,
      reportFetchingInterval: null,
      gotreportdata: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.homeTitle {
  font-size: 20px;
}

.reportTable {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.logo {
  display: flex;
  align-items: center;
  margin-left: 50px;
  margin-top: 40px;
  margin-bottom: 80px;

  img {
    width: 200px;
    height: auto;
  }

  width: 50px;
}

hr {
  color: rgb(149, 149, 149);
  opacity: 15%;
  border-top: 1px solid;
}

.reportbtn {
  background: none;
  border: none;
  color: black;
  font-weight: bold;
  font-size: 14px;
}

.table {
  background: rgb(236, 236, 236);
}

.tableindi {
  background: rgb(255, 255, 255);
}

.tableheader {
  background: rgba(80, 39, 107, 0.728);
  color: white;
}

@media (min-width: 1000px) {
  .modal .modal-huge {
    max-width: 90% !important;
    width: 90% !important;
  }
}

.searchbar {
  margin-bottom: 30px;
  margin-top: 30px;
  width: 180%;
}

.radio {
  margin-right: 10px;
}

.radio-card+.card {
  color: var(--primary);
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid;
  border-color: rgb(223, 223, 223);
  border-radius: 4px;
  margin: auto;
  font-size: 15px;
}

.radio-card:checked+.card {
  border: 5px solid rgba(86, 37, 119, 0.726);
  border-radius: 4px;
  color: rgba(255, 255, 255);
  font-weight: bold;
  background: rgba(96, 47, 129, 0.726);
  width: 220px;
  height: 290px;
  -webkit-animation-name: fadeInCheckbox;
  animation-name: fadeInCheckbox;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.radio-card-holder {
  margin: 20%;
}

.card-img {
  max-width: 100%;
  max-height: 100%;
}

.card {
  width: 200px;
  height: 280px;
  font-size: 14px;
}

.cardreport {
  width: 220px;
  height: 100%;
  font-size: 14px;
}

.cardgroup {
  margin: 10px;
  width: 250px;
}

.textbtn {
  border: none;
  background-color: inherit;
}

.textbtn:hover {
  background: #eee;
}

::v-deep .dsg-purple-th {
  background: rgba(80, 39, 107, 0.728);
  color: white;
}

.btn-purple {
  background: rgba(80, 39, 107, 0.728);
  color: white;
}

.header {
  background: rgba(221, 221, 221, 0.728);
}

.rowreport {
  margin-left: 320px;
  color: rgba(119, 119, 119, 0.728);
  margin-top: 20px;
  margin-bottom: 10px;
}

.buttonmodal {
  width: 200px;
  text-align: center;
  margin-bottom: 20px;
}

.savebtn {
  background: rgba(80, 39, 107, 0.728);
  color: white;
  border: none;
}

.closebtn {
  background: rgb(225, 108, 108);
  color: white;
  border: none;
}

.reportheader {
  margin-left: 50px;
  font-size: 14px;
}

.borderless td,
.borderless th {
  border: none;
}

.reportinput {
  text-align: left;
  font-weight: normal;
  margin-top: 20px;
}

.cardcontainer {
  max-height: 400px;
  max-width: 100%;
  overflow-y: auto;
}

.badgecontainer {
  max-width: 100%;
  overflow-y: auto;
}

.cardcontainernamaste {
  height: 100%;
  width: 95%;
  overflow-y: auto;
}

.indicator {
  color: rgb(142, 142, 142);
}

.badge {
  background: #fbcb04;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-size: 16px;
}
</style>
